<template>
  <div>
    <script @load="authLoaded" src="https://secure.aadcdn.microsoftonline-p.com/lib/1.0.15/js/adal.min.js" integrity="sha384-lIk8T3uMxKqXQVVfFbiw0K/Nq+kt1P3NtGt/pNexiDby2rKU6xnDY8p16gIwKqgI" crossorigin="anonymous" type="application/javascript"></script>
    <v-container class="" fluid> 
      <v-card :elevation="1" :dark="isDark">
        <v-card-title v-if="$store.getters.loading && !event && !race && !result" class="headline">Loading Data...</v-card-title>

        <v-skeleton-loader v-if="$store.getters.loading && !event && !race && !result" type="table"></v-skeleton-loader>
        <EventHeader v-if="event && race" :event="event" :title="race.name" :dark="isDark||true" compact/>
        <div v-if="event && race && result">
          <RaceResultDetails :event="event" :race="race" :result="result" :filter="filter" :meta="meta" :badges="badges" :map="true" :dark="isDark"/>
        </div>
      </v-card>
    </v-container>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import eventService from "@/services/eventService";
import MicrosoftTeamsWrapper from "@/util/microsoftTeamsWrapper";
import EventHeader from "@/components/EventHeader.vue";
import RaceResultDetails from "@/components/RaceResultDetails";
import VueMarkdown from "@/components/VueMarkdown";
import ShareButton from "@/components/ShareButton";
import tenants from '@/data/tenants.config'
import i18n from '@/i18n'
// https://docs.microsoft.com/en-us/javascript/api/overview/msteams-client?view=msteams-client-js-latest
//import * as microsoftTeams from "@microsoft/teams-js";
const tenant = tenants.current();
//const microsoftTeams = window.microsoftTeams;

export default {
  components: {
    EventHeader,
    VueMarkdown,
    RaceResultDetails,
    ShareButton,
    VueMarkdown,
  },
  props: {
    microsoftTeams: Object,
    teamsContext: Object,
  },
  data: () => ({
    showDebug: false,
    event: null,
    race: null,
    result: null,
    badges: null,
    filter: null,
    meta: {}, // we don't have this here
  }),

  async mounted() {
    await this.loadUserData();
    EventBus.$on('login-state-change', async user => {
      await this.loadUserData();
    });
    this.$store.commit('setContext', 'microsoft-teams');
  },

  methods: {
    async loadUserData() {
      console.log('AUTH STATE changed', this.user);
      if (this.user) {
        this.loadConnectedData();
      }
    },
    async authLoaded() {
      //this.tryLoadAuthState();
    },
    
    async loadConnectedData() {
      if (!this.teamsContext) {
        return; 
      }
      const tabData = this.teamsWrapper.parseEntityId(this.teamsContext.entityId);
      if (!this.event) {
        this.event = (await eventService.get(tabData.event_id, /*allowDraft:*/true)).data;
      }
      if (!this.result && tabData && tabData.type === 'result' && this.event) {
        this.race = this.event.races.find(x => x.id === tabData.race_id);
        this.meta = (await eventService.getRaceResultsMeta(this.event.id, this.race.id)).data;
        this.result = (await eventService.getRaceResultDetails(this.event.id, this.race.id, tabData.result_id)).data;
      }
    },
    async teamsLoaded() {
      this.microsoftTeams = window.microsoftTeams;
    },
    async signOut() {
      await this.teamsWrapper.signOut();
    },
    async login() {
      await this.teamsWrapper.login();
    },

  },
  computed: {
    isDark() {
      return this.teamsWrapper.isDarkTheme();
    },
    teamsWrapper() {
      return new MicrosoftTeamsWrapper(this, this.microsoftTeams, this.teamsContext);
    },
    teamsInfoJson() {
      if (this.teamsContext == null) return null;
      return JSON.stringify(this.teamsWrapper.getTeamsInfo());
    },
    objectType() {
      if (this.teamsContext != null && this.teamsContext.channelId) {
        return 'channel';
      }
      return 'chat';
    },

    ...mapGetters({
      user: "user"
    }), 
  },
  watch: {
    microsoftTeams() {
      console.log('teams tab content loaded');
      this.loadConnectedData();
    },
    teamsContext() {     
      this.loadConnectedData();
    },
  }
};
</script>
<style lang="scss" scoped>

 .v-card__actions {flex-flow: wrap;}
.v-application--is-ltr .v-card__actions > .v-btn.v-btn + .v-btn { margin-left: 0; }
</style>

